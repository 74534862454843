const SocialShare = {
  FACEBOOK_URL: 'https://www.facebook.com/sharer/sharer.php?u=',
  X_URL: 'https://twitter.com/intent/tweet?url=',
  LINKEDIN_URL: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  WHATSAPP_URL: 'https://api.whatsapp.com/send?text=',
  EMAIL_URL: 'mailto:',

  init() {
    const facebookButton = document.querySelector('[data-share="facebook"]');
    const xButton = document.querySelector('[data-share="x"]');
    const linkedInButton = document.querySelector('[data-share="linkedin"]');
    const whatsappButton = document.querySelector('[data-share="whatsapp"]');
    const emailButton = document.querySelector('[data-share="email"]');

    if (facebookButton) {
      facebookButton.addEventListener('click', SocialShare.shareOnFacebook);
    }
    if (xButton) {
      xButton.addEventListener('click', SocialShare.shareOnX);
    }
    if (linkedInButton) {
      linkedInButton.addEventListener('click', SocialShare.shareOnLinkedIn);
    }
    if (whatsappButton) {
      whatsappButton.addEventListener('click', SocialShare.shareOnWhatsApp);
    }
    if (emailButton) {
      emailButton.addEventListener('click', SocialShare.shareByEmail);
    }
  },

  shareOnFacebook() {
    const url = window.location.href;
    SocialShare.openPopup(`${SocialShare.FACEBOOK_URL}${encodeURIComponent(url)}`);
  },

  shareOnX() {
    const url = window.location.href;
    const text = document.title;
    SocialShare.openPopup(`${SocialShare.X_URL}${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`);
  },

  shareOnLinkedIn() {
    const url = window.location.href;
    SocialShare.openPopup(`${SocialShare.LINKEDIN_URL}${encodeURIComponent(url)}`);
  },

  shareOnWhatsApp() {
    const url = window.location.href;
    const text = document.title;
    SocialShare.openPopup(`${SocialShare.WHATSAPP_URL}${encodeURIComponent(text + ' ' + url)}`);
  },

  shareByEmail() {
    const url = window.location.href;
    const text = document.title;
    window.location.href = `${SocialShare.EMAIL_URL}?subject=${encodeURIComponent(text)}&body=${encodeURIComponent(text + ' - ' + url)}`;
  },

  openPopup(url) {
    window.open(url, '_blank');
  },
};

module.exports = SocialShare;
