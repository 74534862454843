const burger = require('./burger.js');
const register = require('./register.js');
const flashMessage = require('./flashMessage.js');
const registerAjax = require('./registerAjax.js');
const shares = require('./shares.js');
const cookieConsent = require('./cookie_consent.js');

function initScripts()
{
    burger.init();
    register.init();
    flashMessage.init();
    registerAjax.init();
    shares.init();
    cookieConsent.init();
}

document.addEventListener('DOMContentLoaded', initScripts);
