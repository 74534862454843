const FormHandler = {
  formNames: ['business_registration_form', 'doctor_registration_form', 'registration_form', 'contact_message'],

  init() {
    this.formNames.forEach((formName) => {
      document.querySelectorAll(`[name="${formName}"]`).forEach((form) => {
        form.addEventListener('submit', (e) => this.handleSubmit(e, form, formName));
      });
    });
  },

  handleSubmit(event, form, formName) {
    event.preventDefault();

    const formData = new FormData(form);
    const xhr = new XMLHttpRequest();

    xhr.open('POST', form.action, true);

    xhr.onload = () => {
      form.classList.remove('loading');
      this.clearPreviousErrors(form);

      if (xhr.status >= 200 && xhr.status < 305) {
        const response = JSON.parse(xhr.responseText);
        if (response.redirect) {
          window.location.href = response.redirect;
        }
      } else {
        this.handleErrors(xhr.responseText, form, formName);
      }
    };

    xhr.send(formData);
  },

  clearPreviousErrors(form) {
    // Usuwanie poprzednich błędów tekstowych
    form.querySelectorAll('.register-error').forEach((errorMessage) => {
      errorMessage.remove();
    });

    // Usuwanie błędów dla pól
    form.querySelectorAll('.error-field').forEach((field) => {
      field.classList.remove('error-field');
    });

    // Usuwanie stylów z etykiet
    form.querySelectorAll('.label-error').forEach((label) => {
      label.classList.remove('label-error', 'text-error-500');
    });

    // Resetowanie checkboxów
    form.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      this.resetCheckboxStyle(checkbox, form);
    });

    // Resetowanie innych pól
    form.querySelectorAll('.border-error-500').forEach((field) => {
      this.resetFieldStyle(field);
    });

    // Ukrywanie komunikatów dla checkboxów
    form.querySelectorAll('.terms-error-message').forEach((errorDiv) => {
      errorDiv.classList.add('hidden');
      errorDiv.textContent = ''; // Czyszczenie tekstu błędu
    });
  },

  resetFieldStyle(field) {
    // Resetowanie stylów pól błędów
    field.classList.remove(
      'text-error-500', 'border-error-500', 'bg-error-50',
      'focus:ring-error-500', 'focus:border-error-500', 'placeholder:text-error-500'
    );
    field.classList.add(
      'text-gray-900', 'bg-gray-50', 'border-gray-300',
      'focus:border-blue-500', 'focus:ring-blue-200', 'placeholder:text-gray-400'
    );
  },

  resetCheckboxStyle(checkbox, form) {
    // Resetowanie stylów dla checkboxa
    checkbox.classList.remove('border-error-500', 'bg-error-50', 'focus:ring-error-500', 'focus:border-error-500');

    // Resetowanie stylów powiązanych etykiet
    const labels = form.querySelectorAll(`label[for="${checkbox.id}"]`);
    labels.forEach((label) => {
      label.classList.remove('text-error-500');
      const linkElement = label.querySelector('a');
      if (linkElement) {
        linkElement.classList.remove('text-error-700');
      }
    });
  },

  handleErrors(responseText, form, formName) {
    try {
      const errors = JSON.parse(responseText).errors;

      errors.forEach((error) => {
        const fieldName = error.field;
        const field = form.querySelector(`[name="${formName}[${fieldName}]"]`);

        if (field) {
          this.processFieldError(field, error, form);
        }
      });
    } catch (err) {
      console.error('Error parsing JSON response: ' + err);
    }
  },

  processFieldError(field, error, form) {
    if (field.type === 'checkbox') {
      this.handleCheckboxError(field, error, form);
    } else {
      this.handleInputError(field, error, form);
    }
  },

  handleCheckboxError(field, error, form) {
    const errorDiv = field.closest('.mb-4').querySelector('.terms-error-message');
    if (errorDiv) {
      errorDiv.classList.remove('hidden');
      errorDiv.classList.add('text-error-500');
      errorDiv.textContent = error.message;
    }

    const labels = form.querySelectorAll(`label[for="${field.id}"]`);
    labels.forEach((label) => {
      label.classList.add('text-error-500');
      const linkElement = label.querySelector('a');
      if (linkElement) {
        linkElement.classList.add('text-error-700');
      }
    });

    field.classList.add('border-error-500', 'bg-error-50', 'focus:ring-error-500', 'focus:border-error-500');
  },

  handleInputError(field, error, form) {
    field.classList.add('text-error-500', 'border-error-500', 'bg-error-50', 'focus:ring-error-500', 'focus:border-error-500', 'placeholder:text-error-500');
    field.classList.remove('text-gray-900', 'bg-gray-50', 'border-gray-300', 'focus:border-blue-500', 'focus:ring-blue-200', 'placeholder:text-gray-400');

    const errorMessage = document.createElement('div');
    errorMessage.classList.add('register-error', 'mt-2', 'text-sm', 'text-error-500');
    errorMessage.textContent = error.message;
    field.parentNode.insertBefore(errorMessage, field.nextSibling);
    field.parentNode.classList.add('error-field');

    const fieldId = field.getAttribute('id');
    if (fieldId) {
      const label = form.querySelector(`label[for="${fieldId}"]`);
      if (label) {
        label.classList.add('label-error', 'text-error-500');
      }
    }
  },
};

module.exports = FormHandler;
