const TabSwitcher = {
  TABS_CLASS: 'tab-btn',
  FORMS_CLASS: 'form',
  ACTIVE_TAB_CLASSES: ['bg-bay-of-many-500', 'text-white'],
  INACTIVE_TAB_CLASSES: ['bg-gray-200', 'text-deep-sapphire-500'],
  HIDDEN_CLASS: 'hidden',
  TAB_MAP: {
    doctor: 0,
    business: 1,
    employee: 2
  },

  init() {
    this.tabs = Array.from(document.querySelectorAll(`.${TabSwitcher.TABS_CLASS}`));
    this.forms = Array.from(document.querySelectorAll(`.${TabSwitcher.FORMS_CLASS}`));

    if (this.tabs.length === 0 || this.forms.length === 0) {
      return;
    }

    const errorTab = document.body.dataset.errorTab;
    const activeTab = this.getTabFromUrl();
    const initialIndex = errorTab
      ? parseInt(errorTab, 10)
      : TabSwitcher.TAB_MAP.hasOwnProperty(activeTab)
        ? TabSwitcher.TAB_MAP[activeTab]
        : 0;

    this.activateForm(initialIndex);
    this.addEventListeners();
  },

  activateForm(index) {
    // Ukryj wszystkie formularze
    this.forms.forEach(form => form.classList.add(TabSwitcher.HIDDEN_CLASS));
    this.forms[index].classList.remove(TabSwitcher.HIDDEN_CLASS);

    // Resetuj style przycisków
    this.tabs.forEach(btn => {
      btn.classList.remove(...TabSwitcher.ACTIVE_TAB_CLASSES);
      btn.classList.add(...TabSwitcher.INACTIVE_TAB_CLASSES);
    });

    // Aktywuj wybrany przycisk
    const activeTab = this.tabs[index];
    activeTab.classList.remove(...TabSwitcher.INACTIVE_TAB_CLASSES);
    activeTab.classList.add(...TabSwitcher.ACTIVE_TAB_CLASSES);
  },

  getTabFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('tab');
  },

  updateUrl(index) {
    const url = new URL(window.location);
    const tabName = Object.keys(TabSwitcher.TAB_MAP).find(key => TabSwitcher.TAB_MAP[key] === index);
    url.searchParams.set('tab', tabName);
    history.replaceState(null, '', url);
  },

  addEventListeners() {
    this.tabs.forEach((tab, index) => {
      tab.addEventListener('click', () => {
        this.activateForm(index);
        this.updateUrl(index);
      });
    });
  }
};

module.exports = TabSwitcher;
