import './styles/app.scss';

import './images/image12.jpg';
import './images/lukaszewicz.png';
import './images/politechnika.png';
import './images/StartSmart.jpg';
import './images/universytet_radomski.png';
import './images/4_wspolne_finansowanie_kolor.svg';
import './images/FEPW_RP_UE_RGB-1.png';
import './images/FEPW_RP_UE_RGB-2.png';
import './images/lekarz-1.jpg';

// feature images
import './images/wspieramy-biznes.jpg';
import './images/lekarzy-razem.jpg';
import './images/dbamy_o_zdrowie.jpg';

// hero images
import './images/hero-1.jpg';
import './images/hero-2.jpg';
import './images/hero-3.jpg';
import './images/hero-4.jpg';
import './images/hero-5.jpg';

// logo
import './images/logo-badanie.png';
import './images/nowe-logo-color.svg';
import './images/nowe-logo-white.svg';
import './images/logo-color-3.svg';

// favicon
import './images/favicon/favicon.ico';
import './images/favicon/apple-touch-icon.png';
import './images/favicon/favicon-96x96.png';
import './images/favicon/favicon.svg';
import './images/favicon/web-app-manifest-512x512.png';
import './images/favicon/web-app-manifest-192x192.png';

// scripts
import './scripts/index.js';

// new images
import './images/1.png';
import './images/2.png';
import './images/3.png';
import './images/4.png';
import './images/5.png';
import './images/6.png';
import './images/7.png';
import './images/8.png';
import './images/9.png';
import './images/10.png';
import './images/11.png';
import './images/12.png';
import './images/13.png';
import './images/15.png';
import './images/16.png';
import './images/18.png';
import './images/19.png';
import './images/obowiazki-pracodawcy-4.png';

//images for home page - hero
import './images/bezpieczenstwo-danych.png';
import './images/dbamy-o-zdrowei.png';
import './images/innowacyjna-platforma.png';
import './images/kompleksowa-obsluga-badan-online.png';
import './images/oszczednosc_czasu.png';
import './images/wsparcie.png';
import './images/Wspieranie_biznesu.png';

import './images/contact_free.jpeg'
